<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'
           v-loading='loadingStaus'
           :element-loading-text='loadingText'>
        <el-row :gutter='48'>
          <el-form ref='articleForm' :model='formData' :rules='rules' label-width='140px'>
            <el-col :span='18' class='content-left'>
              <el-form-item label='网站名称' prop='web_name'>
                <el-input v-model='formData.web_name'></el-input>
              </el-form-item>
              <el-form-item label='版权' prop='copyright'>
                <el-input v-model='formData.copyright'></el-input>
              </el-form-item>
              <el-form-item label='统计代码' prop='analytics'>
                <el-input :rows='6' type='textarea' v-model='formData.analytics'></el-input>
              </el-form-item>
              <el-form-item label='SEO标题' prop='seo_title'>
                <el-input v-model='formData.seo_title'></el-input>
              </el-form-item>
              <el-form-item label='SEO关键词' prop='seo_keywords'>
                <el-input v-model='formData.seo_keywords'></el-input>
              </el-form-item>
              <el-form-item label='SEO描述' prop='seo_description'>
                <el-input :rows='6' type='textarea' v-model='formData.seo_description'></el-input>
              </el-form-item>
              <div style='display: flex;flex-direction: row;gap: 10px'>
                <el-form-item label='刊例说明文章ID' prop='seo_keywords'>
                  <el-input v-model='formData.plc_article_id' placeholder='刊例调整说明文章ID'></el-input>
                </el-form-item>
                <el-form-item label='服饰选品规则文章ID' prop='seo_keywords'>
                  <el-input v-model='formData.article_id_selection' placeholder='服饰选品规则文章ID'></el-input>
                </el-form-item>
              </div>

              <el-form-item label='签约部门' prop='seo_keywords'>
                <el-tag
                  type='success'
                  :key='tag'
                  v-for='tag in signatory'
                  closable
                  style='margin-right: 10px'
                  :disable-transitions='false'
                  @close='handleClose(tag)'>
                  {{ tag }}
                </el-tag>

                <el-button type='text' v-show='!dialogVisible' class='button-new-tag' size='small' @click='showInput'>+
                  新部门
                </el-button>

              </el-form-item>
              <!--              <el-form-item>-->
              <!--                <el-button @click="initTemplate">初始化审批模板</el-button>-->
              <!--              </el-form-item>-->
            </el-col>
            <el-col class='content-right' :span='6'>
              <el-form-item label='站点LOGO' prop='web_logo'>
                <ApeUploader :limit='1' @handleUploadSuccess='handleUploadSuccess'
                             @handleUploadRemove='handleUploadRemove' :upload-file-list='uploadFileList'></ApeUploader>
              </el-form-item>
              <div class='right-button'>
                <el-button size='medium' type='primary' :disabled='loadingStaus' @click='saveConfirm'
                           v-if="userPermissions.indexOf('setting_site') != -1">保存
                </el-button>
              </div>
            </el-col>
          </el-form>
        </el-row>
        <div>
          <el-tabs :tab-position='tabPosition' style='height: auto;'>
            <el-tab-pane label='钉钉配置'>
              <el-row>
                <rl-col :span='18'>
                  <el-form :model='config.dingTalk' :inline='false' label-width='100px' :label-position="labelPosition">
                    <el-form-item :label='`${item.label}：`' v-for='(item,field) in config.dingTalk' :key='field'>
                      <!--                  <el-input v-model='config.dingTalk[field].value' :placeholder='item.label' clearable></el-input>-->
                      <el-input style='width: 400px' v-model='item.value' :placeholder='item.label' clearable></el-input>
                    </el-form-item>
                  </el-form>
                </rl-col>
              </el-row>

            </el-tab-pane>
            <!--            <el-tab-pane label="配置管理">配置管理</el-tab-pane>-->
            <!--            <el-tab-pane label="角色管理">角色管理</el-tab-pane>-->
            <!--            <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>-->
          </el-tabs>
        </div>
      </div>
    </PageHeaderLayout>

    <el-dialog
      title='请选择签约数据来源部门'
      :visible.sync='dialogVisible'
      width='30%'
      :before-close='handleConfirm'>
      <div style='width: 100%'>
        <el-row>
          <el-col :span='1.5'>
            部门中选择：
          </el-col>
          <el-col :span='1.5'>
            <CompanySelect :multiple='true' :valueType="'name'" @handleSelect='handleChange' />

          </el-col>

        </el-row>
        <br>
        <el-col :span='1.5'>
          自定义输入(使用 "," 作为分隔号)：
        </el-col>
        <el-col :span='1.5'>
          <el-input v-model='inputValue' @change='inputInit' style='width: 200px' size='mini'></el-input>
        </el-col>
      </div>
      <span slot='footer' class='dialog-footer'>
    <el-button @click='dialogVisible = false'>取 消</el-button>
    <el-button type='primary' @click='dialogVisible = false'>确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeUploader from '@/components/ApeUploader'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SettingSite',
  components: {
    PageHeaderLayout,
    ApeUploader
  },
  data() {
    return {
      hearTitle: '基本设置',
      tabPosition: 'left',
      labelPosition: 'right',
      loadingStaus: true,
      loadingText: '玩命提交中……',
      // 站点基本信息表单结构
      formData: {},
      // 已上传文件列表
      uploadFileList: [],
      // 站点基本信息规则
      rules: {
        web_name: [
          { required: true, message: '输入站点名称', trigger: 'blur' }
        ]
      },
      signatory: [],
      dialogVisible: false,
      options: [],
      value: [],
      inputValue: '',
      config: {
        dingTalk: {
          title: { label: '应用名称', value: '星象仪' },
          corp_id: { label: '唯一标识', value: '' },
          agent_id: { label: '应用ID', value: '' },
          app_key: { label: '应用Key', value: '' },
          app_secret: { label: '应用secret', value: '' },
          aes_key: { label: '订阅es_key', value: '' },
          token: { label: '订阅token', value: '' }
        }
      }


    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    inputInit() {
      if (this.inputValue != '') {
        let data = this.inputValue.split(',')
        this.signatory = [...this.signatory, ..._.difference(data, this.signatory)]
      }
    },
    async showInput() {
      await this.getList()
      this.dialogVisible = true
      this.value = this.signatory

      // this.$nextTick(() => {
      //   this.$refs.saveTagInput.$refs.input.focus()
      // })
    },
    handleChange(selectArr) {
      this.signatory = selectArr
      return selectArr
    },
    async getList() {
      let { list } = await this.$api.getDepartmentAll()
      this.options = list.map((i) => {
        return i.parent_id == 1 ? i : false
      }).filter(Boolean)
    },
    handleConfirm() {

      this.dialogVisible = false
    },

    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['articleForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    handleClose(tag) {
      this.signatory.splice(this.signatory.indexOf(tag), 1)
    },
    // 站点相关信息的保存处理
    async formSubmit() {
      this.loadingStaus = true
      this.formData.signatory = this.signatory.toString()
      let id = await this.$api.saveSettingSiteInfo(this.formData)
      if (id) {
        this.$notify.success('保存成功！')
      }
      this.$nextTick(() => {
        this.loadingStaus = false
      })
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.web_logo = file.id
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.web_logo = 0
      this.uploadFileList = fileList
    },
    // 初始化站点基本信息
    async initSettingSiteForm() {
      // 获取站点分类列表
      let { info } = await this.$api.getSettingSiteInfo()
      this.formData = info
      this.signatory = info.signatory.split(',')
      if (info.web_logo_info) {
        this.uploadFileList = [{
          id: info.web_logo_info.id,
          name: info.web_logo_info.title,
          url: info.web_logo_info.full_path
        }]
      }
    },
    initTemplate() {
      //初始化审批模板

    }
  },
  async mounted() {
    this.loadingStaus = true
    this.initSettingSiteForm()
    this.$nextTick(() => {
      this.loadingStaus = false
    })

  }
}
</script>

<style lang='stylus'>
.content-left
  min-height 640px
  border-right 1px solid #e8e8e8

  .el-cascader
    display block
    width 320px

.content-right
  .right-button
    border-top 1px solid #e8e8e8e8
    padding 12px 12px

  .el-form-item__label
    float none
    display inline-block
    text-align left
    padding 0 0 10px 0

  .el-form-item__content
    margin-left 0 !important

    .el-checkbox-group
      margin-top -12px

</style>
