<template>
<div>
  <page-header-layout>
    <div class='main-page-content'>
      <el-card>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='创建时间：'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @sort-change='changeTableSort' border>
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </el-card>
    </div>
  </page-header-layout>
</div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
export default {
  name: 'notifySettingList',
  components: { PageHeaderLayout },
  data(){
    return {
      loadingStatus:false,
      dataList:[],
      orderSort: { id: 'desc' },
      searchCondition: { date_range: [] },
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      columns: [
        {
          title: 'action',
          value: 'action',
          field: 'type',
          width: 120,
        },
        {
          title: '描述',
          value: 'description',
          width: 220,
          sortable: 'custom'
        },
        {
          title: '模板内容',
          value: 'message_template',
          width: 420,
          sortable: 'custom',
          header_align:'center',
          align:'left',
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  methods:{
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.gender) {
        condition.gender = this.searchCondition.gender
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getNotifySettingConfigList(searchCondition)

      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })

    },
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>