<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-card>
          <div class='table-search'>
            <el-form :model='searchCondition' inline size='mini'>
              <el-form-item label='创建时间：'>
                <el-date-picker
                  v-model='searchCondition.date_range'
                  type='daterange'
                  align='right'
                  unlink-panels
                  range-separator='至'
                  start-placeholder='开始日期'
                  end-placeholder='结束日期'
                  value-format='yyyy-MM-dd'
                  :picker-options='pickerOptions'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class='default-table'>
            <!--    渲染表格-->
            <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                     :loading='loadingStatus' @sort-change='changeTableSort' border>
              <el-table-column label='#' header-align='center' align='center' width='120'>
                <template slot-scope='{row}'>
                  <el-link type='primary' icon='el-icon-view' @click='handleView(row)'>详情</el-link>
                </template>
              </el-table-column>
            </m-table>
            <!--    渲染分页-->
            <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                          @pagination='getList' />
          </div>
        </el-card>
      </div>
    </page-header-layout>
    <!-- 操作日志详细 -->
    <el-dialog title='详情' :visible.sync='open' width='700px' append-to-body>
      <el-form ref='form' :model='form' size='mini'>
        <el-row>
          <el-col :span='24'>
            <el-form-item label='消息ID'>
              <el-link icon='el-icon-refresh' @click='getReadStatus'>{{ form.processQueryKey }}</el-link>
            </el-form-item>
            <el-form-item label='送达情况'>
              <span>{{ form._send_status || form.send_status }}</span>
            </el-form-item>
            <el-form-item label='消息已读情况'>
              <div v-if='messageReadInfoList' style='padding-left: 10%'>
                <br />
                <div v-for='(item,index) in messageReadInfoList' :key='index'>
                  <el-tag effect='plain'>{{ item.name }}</el-tag>
                  &nbsp;
                  <el-tag effect='plain' :type='item.readStatus=="READ"?"success":"danger"'>
                    {{ item.readStatus == 'READ' ? '已读' : '未读' }}消息
                  </el-tag>
                  &nbsp;
                  <span class='el-icon-time'>{{ $utils.parseTime(item.readTimestamp) }}</span>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='open = false'>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'notifyDingTalkList',
  components: { PageHeaderLayout },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      orderSort: { id: 'desc' },
      searchCondition: { date_range: [] },
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      columns: [
        {
          title: '消息类型',
          value: 'key',
          field: 'type',
          width: 120
        },
        {
          title: '数据包',
          value: 'data',
          width: 420,
          sortable: 'custom'
        },
        {
          title: '送达状态',
          value: '_send_status',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '已读信息',
          value: 'message_read_info_list',
          width: 420,
          sortable: 'custom'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 是否显示弹出层
      open: false,
      form: {}
    }
  },
  computed: {
    messageReadInfoList() {
      if (this.form && this.form.message_read_info_list && this.form.message_read_info_list.indexOf('{') > -1)
        return JSON.parse(this.form.message_read_info_list)
      else
        return this.form.message_read_info_list
      // return this.formData.notify_msg || {}
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.gender) {
        condition.gender = this.searchCondition.gender
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getDingtalkLogList(searchCondition)

      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })

    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
    async getReadStatus() {
      await this.$api.getDingtalkNotifyReadStatus({ processQueryKey: this.form.processQueryKey, id: this.form.id })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>