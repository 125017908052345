<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <div class="main-page-content">
          <el-row :gutter="15" class="row">
            <el-col :span="6" class="col-3">
              <span>星象仪 - 红人集合  <span>({{ pdDataList.length }})</span> <el-link type="primary" @click="getPdData"><i
                  class="el-icon-refresh"></i></el-link></span>
              <draggable class="list-group" :list="pdDataList" group="pdArtist">
                <el-card
                    class="list-group-item"
                    v-for="(element, index) in pdDataList"
                    :key="index"
                >
                  {{ index + 1 }}） {{ element.nickname }}
                </el-card>
              </draggable>
            </el-col>
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="12">
                  <span>对照 - 星象仪 <span>({{ listLeft.length }})</span>
                    <el-link type="primary" @click="clearLeftList" title="清空列表"><i
                        class="el-icon-delete"></i></el-link></span>
                  <draggable class="list-group" :list="listLeft" group="pdArtist" @end="onEnd">
                    <el-card
                        class="list-group-item"
                        v-for="(element, index) in listLeft"
                        :key="index"
                    >
                      <span :class="`${element.is_saved?'success':''}`"> {{ index + 1 }}） {{ element.nickname }}</span>
                    </el-card>
                  </draggable>
                </el-col>
                <el-col :span="12">
                  <span>对照 - 星广汇
                    <span>({{ listRight.length }})</span>
                    <el-link type="primary" @click="clearRightList" title="清空列表"><i
                        class="el-icon-delete"></i></el-link></span>
                  <draggable class="list-group" :list="listRight" group="adArtist">
                    <el-card
                        class="list-group-item"
                        v-for="(element, index) in listRight"
                        :key="index"
                    >
                      <span :class="`${element.is_saved?'success':''}`"> {{ index + 1 }}） {{ element.nickname }}</span>
                    </el-card>
                  </draggable>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-button type="default" size="large" class="btn-commit" @click="compareNickname">同名匹配</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="primary" size="large" class="btn-commit" @click="commitMap"
                             :disabled="loadingCommit">{{ loadingCommit ? '提交中...' : '确认提交' }}
                  </el-button>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" class="col-3">
              <span>星广汇 - 红人集合  <span>({{ adDataList.length }})</span> <el-link type="primary" @click="getAdData">
                <i class="el-icon-refresh"></i></el-link></span>
              <draggable class="list-group" :list="adDataList" group="adArtist">
                <el-card
                    class="list-group-item"
                    v-for="(element, index) in adDataList"
                    :key="index"
                >
                  {{ index + 1 }}） {{ element.nickname }}
                </el-card>
              </draggable>
            </el-col>
          </el-row>

        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import draggable from "vuedraggable";
import ApeTable from "@/components/ApeTable";

export default {
  name: 'ArtistMap',
  components: {
    PageHeaderLayout,
    ApeTable,
    draggable
  },
  data() {
    return {
      loadingStatus: false,
      columns: [],
      adDataList: [],
      // 分页信息
      pagingDataAd: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        offset: 0
      },
      pdDataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        offset: 0
      },
      listLeft: [],
      listRight: [],
      page_size: 1000,
      loadingCommit: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    adList() {
      let rightIds = this.listRight.map((item) => {
        return item.id
      })

      return this.adDataList.filter(item => rightIds.indexOf(item.id) === -1)
    },
    pdList() {
      let leftIds = this.listLeft.map((item) => {
        return item.id
      })
      return this.pdDataList.filter(item => leftIds.indexOf(item.id) === -1)
    },
  },
  methods: {
    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function (evt) {
      window.console.log(evt);
    },
    onStart(e) {
      console.log('start e', e)

    },
    async onEnd(e) {
      // console.log('onEnd e', e)
      let index = e.newIndex
      let rmRow = this.pdDataList[index]
      // console.log(rmRow)
      if (rmRow.is_saved) {
        let id = await this.$api.delArtistCompare({ artist_id: rmRow.id })
        console.log('id', id)
        //移除成功
        this.$notify.success(`成功移除:${ rmRow.nickname }`)
      }
    },
    onChoose(e) {
      console.log('onChoose e', e)
    },
    onUnChoose(e) {
      console.log('onUnChoose e', e)
    },
    checkMove(e) {
      console.log('checkMove e', e)
      return true;
    },
    // 切换页码操作
    async switchPaging() {
      this.getPdData()
    },
    excludeData(listRight, list) {
      if (!list)
        return []

      let excludeIds = listRight.map((item) => {
        return item.id
      })

      return list.filter(item => excludeIds ? excludeIds.indexOf(item.id) === -1 : false)
    },
    async getPdData() {
      this.loadingStatus = true
      let pagingInfo = { current_page: 1, page_size: this.page_size }
      let searchCondition = {}
      Object.assign(searchCondition, pagingInfo)
      this.pdDataList = []
      let { list, pages } = await this.$api.getPdCompareList(searchCondition);
      this.$nextTick(() => {
        this.pdDataList = this.excludeData(this.listLeft, list)
      })

      this.pagingData.total = pages.total || 0
      this.pagingData.offset = pages.offset || 0
      this.loadingStatus = false

    },
    async getAdData() {
      this.loadingStatusAd = true

      let pagingInfo = { current_page: 1, page_size: this.page_size }
      let searchCondition = {}
      // if (this.searchCondition.nickname) {
      //   searchCondition.nickname = this.searchCondition.nickname
      // }
      Object.assign(searchCondition, pagingInfo)
      this.adDataList = []
      let { list, pages } = await this.$api.getAdArtistCompareList(searchCondition);

      this.$nextTick(() => {
        this.adDataList = this.excludeData(this.listRight, list)
      })

      this.pagingDataAd.total = pages ? pages.total : 0
      this.loadingStatusAd = false
    },
    clearLeftList() {
      this.listLeft = []
    },
    clearRightList() {
      this.listRight = []
    },
    async commitMap() {
      this.loadingCommit = true
      for (let i in this.listLeft) {
        let rowLeft = this.listLeft[i]
        let rowRight = this.listRight[i]
        if (rowLeft && rowRight) {
          await this.saveCommit(rowLeft, rowRight)
        }
      }
      this.loadingCommit = false
    },
    async saveCommit(rowLeft, rowRight) {
      // this.$notify.warning('提交中')
      let saveInfo = {
        artist_id: rowLeft.id,
        nickname: rowLeft.nickname,
        ad_artist_id: rowRight.id,
        ad_nickname: rowRight.nickname
      }
      await this.saveArtistCompare(saveInfo)
    },
    async saveArtistCompare(row) {
      let id = await this.$api.saveArtistCompare({
        artist_id: row.artist_id,
        nickname: row.nickname,
        ad_artist_id: row.ad_artist_id,
        ad_nickname: row.ad_nickname
      })
      if (id) {
        this.$notify.success(`成功匹配：${ row.nickname } = ${ row.ad_nickname }`)
      }
    },
    async getMapData() {
      let pagingInfo = { current_page: 1, page_size: this.page_size }
      let searchCondition = {}
      Object.assign(searchCondition, pagingInfo)
      let { list_left, list_right } = await this.$api.getCompareList(searchCondition);
      this.listLeft = list_left
      this.listRight = list_right
      // console.log(pages)
    },
    // 比较匹配同名红人
    compareNickname() {
      let total = 0;

      for (let i in this.pdDataList) {
        let leftRow = this.pdDataList[i]
        let nickname = leftRow.nickname
        let rightRow = this.adDataList.find((value) => value.nickname.trim() === nickname.trim())
        if (rightRow) {
          total++
          this.listLeft.push(leftRow)
          this.listRight.push(rightRow)
        }
        // console.log(`匹配：${ leftRow.nickname } =》 ${ rightRow ? rightRow.nickname : '--' }`)
      }
      this.$message.success(`匹配成功：${ total }个`)
      this.pdDataList = this.excludeData(this.listLeft, this.pdDataList)
      this.adDataList = this.excludeData(this.listRight, this.adDataList)
    }
  },
  async mounted() {
    await this.getMapData()
    await this.getPdData()
    await this.getAdData()
  },
}
</script>
<style scoped>
.list-group {
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  padding: 10px;
  height: 80vh;
  overflow-y: scroll;
}

.list-group-item {
  margin-bottom: 5px;
}

.btn-commit {
  width: 90%;
  margin-top: 10px;

}

.success {
  color: #67C23A;
}

</style>
